import { RichTextEditor, Link } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import { Color } from "@tiptap/extension-color";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Text from "@tiptap/extension-text";
import TextStyle from "@tiptap/extension-text-style";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
import Image from "@tiptap/extension-image";
import { v4 as uuid } from "uuid";
import { FC, useState } from "react";
import { uploadPublicFile } from "../../api";
import { IconPhotoPlus } from "@tabler/icons-react";
import { ActionIcon, Text as MantineText } from "@mantine/core";

type Props = {
  content: string;
  onUpdate: (html: string) => void;
};

const FIVE_MB = 5242880;
const imageTypes = ["image/png", "image/jpg", "image/jpeg"];

const RichText: FC<Props> = ({ content, onUpdate }) => {
  const [fileError, setFileError] = useState<string>("");

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      Image,
      Color,
      TextStyle,
      Text,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
    ],
    content,
    onUpdate: ({ editor }) => {
      onUpdate(editor.getHTML());
    },
  });

  const addImage = async (event: any) => {
    setFileError("");
    const file = event?.target?.files[0];
    if (!file) {
      console.error("File isn't uploaded");
      return;
    }
    if (file?.size > FIVE_MB) {
      setFileError("Розмір файлу має бути меньше 5мб");
      return;
    }
    let formData = new FormData();
    const extension = file.name.split(".").pop();
    const name = file.name.replace(/\.[^/.]+$/, "");
    const fileName = `${name}_${uuid()}.${extension}`;
    const uploadedFile = new File([file], fileName, { type: file.type });
    formData.append("file", uploadedFile);
    formData.append("fileName", fileName);
    const { fileUrl } = await uploadPublicFile(formData);
    if (fileUrl && editor) {
      editor.chain().focus().setImage({ src: fileUrl }).run();
    }
  };

  return (
    <>
      {fileError && (
        <MantineText mb="md" c="red">
          {fileError}
        </MantineText>
      )}
      <RichTextEditor
        editor={editor}
        onKeyDown={async (event?: React.KeyboardEvent<HTMLDivElement>) => {
          if (!event) {
            return;
          }

          const code = event.which || event.keyCode;

          let charCode = String.fromCharCode(code).toLowerCase();

          if ((event.ctrlKey || event.metaKey) && charCode === "v") {
            const clipboardContents = await navigator.clipboard.read();
            const clipboardItem = clipboardContents[0];

            const imageType = imageTypes.find(
              (type) => !!clipboardItem.types.includes(type),
            );
            if (imageType) {
              event.preventDefault();

              const blob = await clipboardItem.getType(imageType);
              const name = `${uuid()}.${imageType.split("/")[1]}`;
              const file = new File([blob], name, { type: blob.type });

              if (file?.size > FIVE_MB) {
                if (editor) {
                  editor
                    .chain()
                    .focus()
                    .insertContent(
                      '<p><span style="color: #FF0000">File should be lower then 5mb</span></p>',
                    )
                    .run();
                }
                return;
              }
              let formData = new FormData();
              formData.append("file", file);
              formData.append("fileName", name);
              try {
                const { fileUrl } = await uploadPublicFile(formData);
                if (fileUrl && editor) {
                  editor.chain().focus().setImage({ src: fileUrl }).run();
                }
              } catch (e) {
                if (editor) {
                  editor
                    .chain()
                    .focus()
                    .insertContent(
                      '<p><span style="color: #FF0000">File upload error</span></p>',
                    )
                    .run();
                }
              }
            }
          }
        }}
      >
        <RichTextEditor.Toolbar c="black">
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.ClearFormatting />
            <RichTextEditor.Highlight />
            <RichTextEditor.Code />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.H1 />
            <RichTextEditor.H2 />
            <RichTextEditor.H3 />
            <RichTextEditor.H4 />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Blockquote />
            <RichTextEditor.Hr />
            <RichTextEditor.BulletList />
            <RichTextEditor.OrderedList />
            <RichTextEditor.Subscript />
            <RichTextEditor.Superscript />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Link />
            <RichTextEditor.Unlink />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.AlignLeft />
            <RichTextEditor.AlignCenter />
            <RichTextEditor.AlignJustify />
            <RichTextEditor.AlignRight />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <input
              type="file"
              onChange={addImage}
              accept={imageTypes.join(",")}
              name="secondaryLogo"
              style={{ display: "none" }}
              id="secondary-logo-upload"
              multiple={false}
            />
            <ActionIcon
              variant="default"
              aria-label="Go-to"
              size={26}
              component="label"
              htmlFor="secondary-logo-upload"
            >
              <IconPhotoPlus style={{ width: 16, height: 16 }} stroke={1} />
            </ActionIcon>
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Undo />
            <RichTextEditor.Redo />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>

        <RichTextEditor.Content />
      </RichTextEditor>
    </>
  );
};
export default RichText;
