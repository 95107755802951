import { Button, Flex, Title } from "@mantine/core";
import { FC } from "react";
import classes from "./PageContainer.module.css";
import { COURSE_LESSON_PATH, COURSE_PATH } from "../../pages/routes";
import { ReactComponent as BackButtonArrow } from "../../assets/icons/BackButtonArrow.svg";
import { Link, generatePath, matchPath, useParams } from "react-router-dom";
import UserIcon from "../UserIcon";
import { useMediaQuery } from "@mantine/hooks";
import useUser from "../../hooks/useUser";

type Props = {
  title: string;
  children: React.ReactNode;
};

const PageContainer: FC<Props> = ({ children, title }) => {
  const { user } = useUser();
  const isMobile = useMediaQuery("(max-width: 48em");

  const { courseId } = useParams();
  const isLessonPage = matchPath(COURSE_LESSON_PATH, window.location.pathname);

  const BackToCourseButton = () => (
    <Button
      leftSection={<BackButtonArrow />}
      className={classes.backToCourseButton}
      component={Link}
      to={generatePath(COURSE_PATH, { courseId: courseId || "" })}
    >
      Повернутися до всіх матеріалів
    </Button>
  );
  return (
    <div className={classes.page}>
      <Flex pb="40px" mb="20px" className={classes.header}>
        {isLessonPage ? (
          <BackToCourseButton />
        ) : (
          <Title order={3}>{title}</Title>
        )}
        {!isMobile && <UserIcon avatar={user?.avatar} ml="auto" />}
      </Flex>
      {children}
    </div>
  );
};

export default PageContainer;
