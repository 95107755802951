import { ActionIcon, Box, Button, Flex, Text, Title } from "@mantine/core";
import classes from "../../StudyFlow.module.css";
import {
  Course,
  getCourseLesson,
  Lesson,
  LessonBlockType,
} from "../../../../../api/courses";
import { FC } from "react";
import HomeWorksList from "./HomeWorksList";
import { electricLimeHex } from "../../../../../theme";
import { IconEye } from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { useParams } from "react-router-dom";
import { finishLessonForAllUsers } from "../../../../../api/studyFlows";
import { useQuery } from "@tanstack/react-query";

type Props = {
  course: Course;
  refetchStudyFlow: () => Promise<any>;
};

const Lessons: FC<Props> = ({ course, refetchStudyFlow }) => {
  const { studyFlowId } = useParams();

  const lessons = course?.courseBlocks?.length
    ? course?.courseBlocks.reduce((acc, cur) => {
        const blockLessons = cur.courseLessons.length
          ? cur.courseLessons.map((lesson) => ({
              ...lesson,
              courseBlockId: cur._id,
            }))
          : [];
        return [...acc, ...blockLessons];
      }, [] as Lesson[])
    : [];

  return (
    <div className={classes.pageContent}>
      <Flex justify="space-between" mb="30px">
        <Flex align="center">
          <Title order={3} mr="20px">
            Домашні завдання
          </Title>
        </Flex>
      </Flex>
      <div>
        {!!lessons.length
          ? lessons.map((lesson) => (
              <LessonComponent
                studyFlowLesson={lesson}
                courseId={course._id}
                studyFlowId={studyFlowId}
              />
            ))
          : "Немає уроків на потоці"}
      </div>
    </div>
  );
};

const LessonComponent = ({
  studyFlowLesson,
  courseId,
  studyFlowId,
}: {
  studyFlowLesson: Lesson;
  courseId: string;
  studyFlowId?: string;
}) => {
  const { data: lesson, refetch } = useQuery({
    queryKey: [
      "lesson",
      studyFlowId,
      courseId,
      studyFlowLesson.courseBlockId,
      studyFlowLesson._id,
    ],
    queryFn: async () =>
      courseId && studyFlowLesson.courseBlockId && studyFlowLesson._id
        ? getCourseLesson(
            courseId,
            studyFlowLesson.courseBlockId,
            studyFlowLesson._id,
          )
        : undefined,
  });
  const openShowLessonModal = (lesson: Lesson) =>
    modals.openConfirmModal({
      title: "Підтвердіть дію",
      size: "sm",
      radius: "md",
      withCloseButton: false,
      children: (
        <Text size="sm">
          Ви впевнені що хочете зробити урок <b>{lesson.name}</b> доступним для
          всіх учнів?
        </Text>
      ),
      labels: { confirm: "Підтвердити", cancel: "Відмінити" },
      onCancel: () => {},
      onConfirm: async () => {
        if (!studyFlowId) {
          return;
        }
        await finishLessonForAllUsers(studyFlowId, lesson._id);
        await refetch();
      },
    });
  return (
    <Box key={studyFlowLesson._id} p={8}>
      <Flex mb={16} justify="space-between" align="center">
        <Flex>
          <Text c={electricLimeHex} fz="16px" mr="4px">
            Назва уроку:
          </Text>
          <Text fw={500} fz="16px">
            {studyFlowLesson.name}
          </Text>
        </Flex>
        {!lesson?.open && (
          <ActionIcon
            variant="filled"
            aria-label="Remove"
            onClick={() => openShowLessonModal(studyFlowLesson)}
          >
            <IconEye style={{ width: "70%", height: "70%" }} stroke={1.5} />
          </ActionIcon>
        )}
      </Flex>

      <HomeWorksList lesson={studyFlowLesson} />
    </Box>
  );
};

export default Lessons;
