import { ActionIcon, Box, Button, Flex, Text, Title } from "@mantine/core";
import ListItem from "../../../components/ListItem";
import AddCourseModal from "./components/AddCourseModal";
import { useDisclosure } from "@mantine/hooks";
import { useQuery } from "@tanstack/react-query";
import classes from "./StudyFlow.module.css";
import clsx from "clsx";
import PageContainer from "../../../components/PageContainer";
import {
  addStudyFlowCourse,
  addStudyFlowMentor,
  addStudyFlowUser,
  Event,
  getEvents,
  getStudyFlow,
  removeStudyFlowCourse,
  removeStudyFlowMentor,
  removeStudyFlowUser,
  updateEvent,
  createEvent,
  removeEvent,
} from "../../../api/studyFlows";
import { generatePath, Link, useNavigate, useParams } from "react-router-dom";
import { RESOURCES_PATH, STUDY_FLOWS_PATH } from "../../routes";
import dayjs from "dayjs";
import { modals } from "@mantine/modals";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { User, UserRole } from "../../../api/users";
import AddUsersModal from "./components/AddUsersModal";
import Lessons from "./components/Lessons";
import useUser from "../../../hooks/useUser";
import { useState } from "react";
import AddEventModal, { eventDateFormat } from "./components/AddEventModal";

const StudyFlow = () => {
  const { studyFlowId } = useParams();
  const navigate = useNavigate();
  const { user } = useUser();
  const { data: studyFlow, refetch } = useQuery({
    queryKey: ["studyFlow", studyFlowId],
    queryFn: async () => (studyFlowId ? getStudyFlow(studyFlowId) : undefined),
  });
  const { data: events, refetch: refetchEvents } = useQuery({
    queryKey: ["events", studyFlowId],
    queryFn: async () => (studyFlowId ? getEvents(studyFlowId) : undefined),
  });

  const [opened, { open, close }] = useDisclosure(false);
  const [openedUsersModal, { open: openUsersModal, close: closeUsersModal }] =
    useDisclosure(false);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

  const [openedEventModal, { open: openEventModal, close: closeEventModal }] =
    useDisclosure(false);
  const [
    openedMentorsModal,
    { open: openMentorsModal, close: closeMentorsModal },
  ] = useDisclosure(false);

  const openRemoveUserModal = (selectedUser: User) =>
    modals.openConfirmModal({
      title: "Підтвердіть дію",
      size: "sm",
      radius: "md",
      withCloseButton: false,
      children: (
        <Text size="sm">
          Чи впевнені що хочете видалити{" "}
          {selectedUser.role === UserRole.MENTOR ? "ментора" : "студента"}{" "}
          <b>
            {selectedUser.firstName} {selectedUser.lastName}
          </b>{" "}
          з потоку?
        </Text>
      ),
      labels: { confirm: "Підтвердити", cancel: "Відмінити" },
      onCancel: () => {},
      onConfirm: async () => {
        if (!studyFlowId) {
          return;
        }

        if (selectedUser.role === UserRole.USER) {
          await removeStudyFlowUser(studyFlowId, selectedUser._id);
        } else if (selectedUser.role === UserRole.MENTOR) {
          await removeStudyFlowMentor(studyFlowId, selectedUser._id);
        }
        await refetch();
      },
    });
  const openRemoveEventModal = (event: Event) =>
    modals.openConfirmModal({
      title: "Підтвердіть дію",
      size: "sm",
      radius: "md",
      withCloseButton: false,
      children: (
        <Text size="sm">
          Чи впевнені що хочете видалити <b>{event.name}</b> подію?
        </Text>
      ),
      labels: { confirm: "Підтвердити", cancel: "Відмінити" },
      onCancel: () => {},
      onConfirm: async () => {
        if (studyFlowId) {
          await removeEvent(studyFlowId, event._id);
          await refetchEvents();
        }
      },
    });
  if (!studyFlow || !studyFlowId) {
    navigate(STUDY_FLOWS_PATH);
    return null;
  }

  const openRemoveCourseModal = () =>
    modals.openConfirmModal({
      title: "Підтвердіть дію",
      size: "sm",
      radius: "md",
      withCloseButton: false,
      children: (
        <Text size="sm">Чи впевнені що хочете видалити курс з потоку?</Text>
      ),
      labels: { confirm: "Підтвердити", cancel: "Відмінити" },
      onCancel: () => {},
      onConfirm: async () => {
        await removeStudyFlowCourse(studyFlow._id);
        await refetch();
      },
    });
  const createUsers = async (selectedUsers: User[]) => {
    await Promise.all(
      selectedUsers.map(async (user) => {
        if (user.role === UserRole.USER) {
          await addStudyFlowUser(studyFlow._id, user._id);
        } else if (user.role === UserRole.MENTOR) {
          await addStudyFlowMentor(studyFlowId, user._id);
        }
      }),
    );
  };

  const isAdmin = user.role === UserRole.ADMIN;

  return (
    <PageContainer title="Потік">
      <Button
        component={Link}
        to={generatePath(RESOURCES_PATH, {
          studyFlowId: studyFlow._id,
        })}
        size="md"
        fz="md"
        className={classes.resourcesButton}
      >
        Переглянути Ресурси
      </Button>
      <Flex pt="24px" pb="24px">
        <Box mr="30px">
          Початок: {dayjs(studyFlow.startDate).format("YYYY-MM-DD")}
        </Box>
        <Box>Кінець: {dayjs(studyFlow.endDate).format("YYYY-MM-DD")}</Box>
      </Flex>
      <div className={classes.pageContent}>
        <Flex justify="space-between">
          <Flex className={classes.courseNameBlock}>
            <Title order={3} mr="30px">
              Назва Курса
            </Title>
            <Text
              fw="normal"
              fz={15}
              c={!studyFlow?.course?.name ? "dimmed" : "white"}
            >
              {studyFlow?.course?.name ?? "Не додано"}
            </Text>
          </Flex>
          {isAdmin && (
            <Flex className={classes.courseNameBlock}>
              <Button
                disabled={!!studyFlow?.course?._id}
                className={classes.addButton}
                onClick={open}
              >
                Додати
              </Button>
              <Button
                disabled={!studyFlow?.course?._id}
                onClick={openRemoveCourseModal}
              >
                Видалити
              </Button>
            </Flex>
          )}
        </Flex>
      </div>
      <div className={classes.pageContent}>
        <Flex justify="space-between" mb="30px">
          <Flex align="center">
            <Title order={3} mr="20px">
              Студенти
            </Title>
          </Flex>
          {isAdmin && (
            <Flex>
              <Button
                disabled={!studyFlow?.course?._id}
                mr="16px"
                onClick={openUsersModal}
              >
                Додати
              </Button>
            </Flex>
          )}
        </Flex>
        <div>
          {!!studyFlow?.users?.length
            ? studyFlow?.users.map((courseUser) => (
                <ListItem key={courseUser._id} variant="contained">
                  <Flex justify="space-between" align="center">
                    <Flex w="100%" wrap="nowrap" className={classes.fieldData}>
                      <Flex w="33%">
                        {courseUser.firstName} {courseUser.lastName}
                      </Flex>
                      <Flex w="33%">{courseUser.email}</Flex>
                      <Flex w="33%" className={classes.roleField}>
                        {courseUser.role}
                      </Flex>
                    </Flex>
                    {isAdmin && (
                      <ActionIcon
                        variant="filled"
                        aria-label="Remove"
                        onClick={() => {
                          openRemoveUserModal(courseUser);
                        }}
                      >
                        <IconTrash
                          style={{ width: "70%", height: "70%" }}
                          stroke={1.5}
                        />
                      </ActionIcon>
                    )}
                  </Flex>
                </ListItem>
              ))
            : "Немає студентів на потоці"}
        </div>
      </div>
      <div className={classes.pageContent}>
        <Flex justify="space-between" mb="30px">
          <Flex align="center">
            <Title order={3} mr="20px">
              Ментори
            </Title>
          </Flex>
          {isAdmin && (
            <Flex>
              <Button
                disabled={!studyFlow?.course?._id}
                mr="16px"
                onClick={openMentorsModal}
              >
                Додати
              </Button>
            </Flex>
          )}
        </Flex>
        <div>
          {!!studyFlow?.mentors.length
            ? studyFlow?.mentors.map((courseUser) => (
                <ListItem key={courseUser._id} variant="contained">
                  <Flex justify="space-between" align="center">
                    <Flex w="100%" wrap="nowrap" className={classes.fieldData}>
                      <Flex w="33%">
                        {courseUser.firstName} {courseUser.lastName}
                      </Flex>
                      <Flex w="33%">{courseUser.email}</Flex>
                      <Flex w="33%" className={classes.roleField}>
                        {courseUser.role}
                      </Flex>
                    </Flex>
                    {isAdmin && (
                      <ActionIcon
                        variant="filled"
                        aria-label="Remove"
                        onClick={() => {
                          openRemoveUserModal(courseUser);
                        }}
                      >
                        <IconTrash
                          style={{ width: "70%", height: "70%" }}
                          stroke={1.5}
                        />
                      </ActionIcon>
                    )}
                  </Flex>
                </ListItem>
              ))
            : "Немає менторів на потоці"}
        </div>
      </div>
      <div className={classes.pageContent}>
        <Flex justify="space-between" mb="30px">
          <Flex align="center">
            <Title order={3} mr="20px">
              Події
            </Title>
          </Flex>
          <Flex>
            <Button
              disabled={!studyFlow?.course?._id}
              mr="16px"
              onClick={openEventModal}
            >
              Додати
            </Button>
          </Flex>
        </Flex>
        <div>
          {!!events?.length
            ? events.map((event) => (
                <ListItem key={event._id} variant="contained">
                  <Flex justify="space-between" align="center">
                    <Flex w="100%" wrap="nowrap" className={classes.fieldData}>
                      <Flex className={classes.eventField}>{event.name}</Flex>
                      <Flex
                        className={clsx(classes.eventField, classes.roleField)}
                      >
                        {dayjs(event.startAt).format(eventDateFormat)}
                      </Flex>
                    </Flex>
                    <>
                      <ActionIcon
                        variant="filled"
                        aria-label="Edit"
                        mr="10px"
                        onClick={() => {
                          openEventModal();
                          setSelectedEvent(event);
                        }}
                      >
                        <IconEdit
                          style={{ width: "70%", height: "70%" }}
                          stroke={1.5}
                        />
                      </ActionIcon>
                      <ActionIcon
                        variant="filled"
                        aria-label="Remove"
                        onClick={() => {
                          openRemoveEventModal(event);
                        }}
                      >
                        <IconTrash
                          style={{ width: "70%", height: "70%" }}
                          stroke={1.5}
                        />
                      </ActionIcon>
                    </>
                  </Flex>
                </ListItem>
              ))
            : "Немає подій на потоці"}
        </div>
      </div>
      {studyFlow?.course && user.role === UserRole.MENTOR && (
        <Lessons course={studyFlow?.course} refetchStudyFlow={refetch} />
      )}

      {opened && (
        <AddCourseModal
          defaultValue={studyFlow?.course?._id}
          opened={opened}
          onClose={() => {
            close();
          }}
          onSubmit={async ({ courseId }) => {
            await addStudyFlowCourse(studyFlow._id, courseId);
            await refetch();
          }}
        />
      )}
      {openedUsersModal && (
        <AddUsersModal
          role={UserRole.USER}
          studyFlowUsers={studyFlow.users || []}
          opened={openedUsersModal}
          onClose={() => {
            closeUsersModal();
          }}
          onSubmit={async (selectedUsers) => {
            await createUsers(selectedUsers);
            await refetch();
          }}
        />
      )}
      {openedMentorsModal && (
        <AddUsersModal
          role={UserRole.MENTOR}
          studyFlowUsers={studyFlow.mentors || []}
          opened={openedMentorsModal}
          onClose={() => {
            closeMentorsModal();
          }}
          onSubmit={async (selectedUsers) => {
            await createUsers(selectedUsers);
            await refetch();
          }}
        />
      )}
      {openedEventModal && (
        <AddEventModal
          defaultValues={selectedEvent}
          opened={openedEventModal}
          onClose={() => {
            if (selectedEvent) {
              setSelectedEvent(null);
            }
            closeEventModal();
          }}
          onSubmit={async (submittedEvent) => {
            if (selectedEvent) {
              await updateEvent(studyFlowId, selectedEvent._id, submittedEvent);
            } else {
              await createEvent(studyFlowId, submittedEvent);
            }
            await refetchEvents();
          }}
        />
      )}
    </PageContainer>
  );
};

export default StudyFlow;
