import { Button, Flex, Title } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import classes from "./Course.module.css";
import PageContainer from "../../../components/PageContainer";
import { useNavigate, useParams } from "react-router-dom";
import { STUDY_FLOWS_PATH } from "../../routes";
import { getCourse, getCourseResources } from "../../../api/courses";
import CreateCourseResourceForm from "./components/CreateCourseResourceForm";
import { bodyTextColorHex } from "../../../theme";
import ResourceItem from "./components/ResourceItem";
import { getStudyFlow } from "../../../api/studyFlows";

const Resources = () => {
  const { studyFlowId } = useParams();
  const navigate = useNavigate();

  const { data: studyFlow } = useQuery({
    queryKey: ["studyFlow", studyFlowId],
    queryFn: async () => (studyFlowId ? getStudyFlow(studyFlowId) : undefined),
  });
  const { data: resources, refetch: refetchResources } = useQuery({
    queryKey: ["resources", studyFlowId],
    queryFn: async () =>
      studyFlowId ? getCourseResources(studyFlowId) : undefined,
  });

  if (!studyFlow || !studyFlowId) {
    navigate(STUDY_FLOWS_PATH);
    return null;
  }

  return (
    <PageContainer title={`Ресурси ${studyFlow?.name}`}>
      <Flex pt="24px" pb="24px" className={classes.header}>
        <CreateCourseResourceForm
          courseId={studyFlow._id}
          refetchResources={refetchResources}
        />
      </Flex>
      {resources?.length ? (
        resources.map((resource) => (
          <ResourceItem
            key={resource._id}
            resource={resource}
            studyFlowId={studyFlow._id}
            refetchResources={refetchResources}
          />
        ))
      ) : (
        <Title
          order={4}
          fz={24}
          fw="normal"
          c={bodyTextColorHex}
          mb={48}
          mt={70}
          ta="center"
        >
          Цей курс не має ресурсів
        </Title>
      )}
    </PageContainer>
  );
};

export default Resources;
