import { Flex, Title } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import classes from "./Course.module.css";
import PageContainer from "../../../components/PageContainer";
import { useNavigate, useParams } from "react-router-dom";
import { STUDY_FLOWS_PATH } from "../../routes";
import { getCourse } from "../../../api/courses";
import CreateCourseBlockForm from "./components/CreateCourseBlockForm";
import { bodyTextColorHex } from "../../../theme";
import CourseBlock from "./components/CourseBlock";

const Course = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();

  const { data: course, refetch } = useQuery({
    queryKey: ["course"],
    queryFn: async () => courseId && getCourse(courseId),
  });

  if (!course || !courseId) {
    navigate(STUDY_FLOWS_PATH);
    return null;
  }

  return (
    <PageContainer title={course?.name}>
      <Flex pt="24px" pb="24px" className={classes.header}>
        <CreateCourseBlockForm
          courseId={course._id}
          refetchCourseBlocks={refetch}
        />
      </Flex>
      {course.courseBlocks?.length ? (
        course.courseBlocks.map((courseBlock) => (
          <CourseBlock
            key={courseBlock._id}
            courseBlock={courseBlock}
            courseId={course._id}
            refetchCourseBlocks={refetch}
          />
        ))
      ) : (
        <Title
          order={4}
          fz={24}
          fw="normal"
          c={bodyTextColorHex}
          mb={48}
          mt={70}
          ta="center"
        >
          Цей курс не має блоків
        </Title>
      )}
    </PageContainer>
  );
};

export default Course;
