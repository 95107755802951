import { FC, useState } from "react";
import { ActionIcon, Flex, Text } from "@mantine/core";
import classes from "../Course.module.css";
import {
  IconDeviceFloppy,
  IconEdit,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import {
  removeCourseResource,
  Resource,
  updateCourseResource,
} from "../../../../api/courses";
import { modals } from "@mantine/modals";
import Input from "../../../../components/Inputs/Input";
import RichTextContent from "../../../../components/RichTextContent";
import RichText from "../../../../components/RichText";

type Props = {
  resource: Resource;
  studyFlowId: string;
  refetchResources: () => Promise<any>;
};

const ResourceItem: FC<Props> = ({
  resource,
  studyFlowId,
  refetchResources,
}) => {
  const [editableName, setEditableName] = useState<string | null>(null);
  const [html, setHTML] = useState<string>(resource.text);

  const openRemoveModal = (courseBlock: Resource) =>
    modals.openConfirmModal({
      title: "Підтвердіть дію",
      size: "sm",
      radius: "md",
      withCloseButton: false,
      children: (
        <Text size="sm">
          Чи впевнені що хочете видалити ресурс <b>{courseBlock.title}</b>?
        </Text>
      ),
      labels: { confirm: "Підтвердити", cancel: "Відмінити" },
      onCancel: () => {},
      onConfirm: async () => {
        await removeCourseResource(studyFlowId, resource._id);
        await refetchResources();
      },
    });

  return (
    <Flex className={classes.pageContent}>
      <Flex justify="flex-end" mb="20px">
        {editableName !== null ? (
          <>
            <ActionIcon
              variant="filled"
              aria-label="Remove"
              disabled={editableName.length <= 2}
              bg="green"
              mr="6px"
              onClick={async () => {
                await updateCourseResource(
                  studyFlowId,
                  resource._id,
                  editableName,
                  html,
                );
                setEditableName(null);
                refetchResources();
              }}
            >
              <IconDeviceFloppy
                style={{ width: "70%", height: "70%" }}
                stroke={1.5}
              />
            </ActionIcon>
            <ActionIcon
              variant="filled"
              aria-label="Remove"
              mr="6px"
              onClick={() => setEditableName(null)}
            >
              <IconX style={{ width: "70%", height: "70%" }} stroke={1.5} />
            </ActionIcon>
          </>
        ) : (
          <ActionIcon
            variant="filled"
            aria-label="Remove"
            mr="6px"
            onClick={() => setEditableName(resource.title)}
          >
            <IconEdit style={{ width: "70%", height: "70%" }} stroke={1.5} />
          </ActionIcon>
        )}

        <ActionIcon
          variant="filled"
          aria-label="Remove"
          disabled={!!editableName}
          onClick={() => {
            openRemoveModal(resource);
          }}
        >
          <IconTrash style={{ width: "70%", height: "70%" }} stroke={1.5} />
        </ActionIcon>
      </Flex>
      <Flex align="center" justify="space-between" w="100%">
        <Flex align="center" w="100%">
          {editableName !== null ? (
            <Flex w="100%" direction="column">
              <Input
                value={editableName}
                onChange={(e) => {
                  const value = e.target.value;

                  setEditableName(value);
                }}
                required
                w="100%"
                mr="sm"
                mb="md"
                placeholder="Введіть назву ресурсу"
              />
              <RichText content={html} onUpdate={setHTML} />
            </Flex>
          ) : (
            <Flex direction="column" mr="16px">
              <Text fz={24}>{resource.title}</Text>
              <RichTextContent content={resource.text} />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ResourceItem;
