import { Flex, Image } from "@mantine/core";
import clsx from "clsx";
import classes from "./Navbar.module.css";
import { LinksGroup } from "../NavbarLinksGroup";
import {
  COURSES_PATH,
  STUDY_FLOWS_PATH,
  ROOT_PATH,
  COURSE_LESSON_PATH,
  COURSE_PATH,
  STUDY_FLOW_PATH,
  RESOURCES_PATH,
} from "../../pages/routes";
import LogoRed from "../../assets/images/LogoRed.png";
import { useMediaQuery } from "@mantine/hooks";
import MobileDropdown from "./MobileDropdown";
import UserIcon from "../UserIcon";
import { NavItem } from "./types";
import { User } from "../../api/users";

const navItems = [
  { id: "1", label: "Загальне", to: ROOT_PATH, active: true },
  {
    id: "2",
    label: "Курси",
    to: COURSES_PATH,
    links: [
      { parentId: "2", label: "Урок", link: COURSE_LESSON_PATH, hidden: true },
      { parentId: "2", label: "Курс", link: COURSE_PATH, hidden: true },
    ],
  },
  {
    id: "3",
    label: "Потоки",
    to: STUDY_FLOWS_PATH,
    links: [
      { parentId: "3", label: "Потік", link: STUDY_FLOW_PATH, hidden: true },
      { parentId: "3", label: "Ресурси", link: RESOURCES_PATH, hidden: true },
    ],
  },
] as NavItem[];

function Navbar({ user }: { user: User }) {
  const isMobile = useMediaQuery("(max-width: 48em");

  const links = navItems.map((item) => (
    <LinksGroup {...item} key={item.label} />
  ));

  return (
    <nav className={classes.navbar}>
      <Flex className={classes.header}>
        <Image
          className={clsx(classes.logo, isMobile && classes.smallLogo)}
          src={LogoRed}
          fit="contain"
        />
        {isMobile && <UserIcon avatar={user?.avatar} />}
      </Flex>
      {isMobile ? (
        <MobileDropdown links={links} navItems={navItems} />
      ) : (
        <div className={classes.linksInner}>{links}</div>
      )}
    </nav>
  );
}

export default Navbar;
